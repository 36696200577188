import { auth0Login } from '../../auth0/sso'

const BASE_SELECTOR = '.js-user-login'
export const OPEN_HORIZONS_MENU_EVENT = 'fastbooking:menu:myiberostar:open'

function initAuth0() {
  document.querySelectorAll(`${BASE_SELECTOR} .js-user-options-link`).forEach(item => {
    item.addEventListener('click', () => {
      if (!IB.browsing_sessions.isUserLogged()) {
        auth0Login(false)
      } else {
        const event = new Event(OPEN_HORIZONS_MENU_EVENT)
        document.dispatchEvent(event)
      }
    })
  })
}

const init = function () {
  IB.browsing_sessions.readRedis(initAuth0)

  if (IB.browsing_sessions.isUserLogged()) {
    const nameElement = document.querySelector(`${BASE_SELECTOR} .js-user-name`)

    let user = null
    if (IB.browsing_sessions !== null && IB.browsing_sessions.getData()) {
      user = IB.browsing_sessions.getData().redis_values
    }

    if (user) {
      nameElement.textContent = user.name
    } else {
      nameElement.textContent = nameElement.dataset.nameSoft
    }
  }
}

export default init
