<template>
  <div class="reb-main-nav">
    <nav class="reb-main-nav__items">
      <ul class="reb-main-nav__list mb-7">
        <RebMainNavItem
          v-if="isUserLogged"
          :item="{
            name: panelTitle,
            url: '#',
            badge: 'myiberostar',
          }"
          :is-selected="selectedItem === 'myiberostar'"
          @selected="setSelectedItem"
        />

        <RebMainNavItem
          v-for="(item, index) in mainMenu"
          :key="index"
          :item="item"
          :utag-event-info="item.utag_event_info"
          :is-selected="selectedItem === item.badge"
          @selected="setSelectedItem"
        />
      </ul>
      <div class="reb-main-nav__accessibility mb-4">
        <AccessibilityButton :config="a11yConfig" />
      </div>
      <div class="reb-main-nav__localization">
        <LanguageSelector>
          <slot name="language_selector"></slot>
        </LanguageSelector>
        <CurrencySelector>
          <slot name="currency_selector"></slot>
        </CurrencySelector>
      </div>
    </nav>
    <!-- .reb-main-nav__items -->
    <div class="reb-main-nav__panels">
      <RebMainNavHotelsDestinations>
        <slot name="hotels_and_destinations"></slot>
      </RebMainNavHotelsDestinations>
      <RebMainNavOffers>
        <slot name="offers"></slot>
      </RebMainNavOffers>
      <RebMainNavBookings v-if="!isIbp">
        <slot name="bookings"></slot>
      </RebMainNavBookings>
      <RebMainNavLastSearchs v-if="userLastSearches">
        <slot name="last_searchs"></slot>
      </RebMainNavLastSearchs>
      <RebMainNavHorizons v-if="!isIbp">
        <slot name="horizons"></slot>
      </RebMainNavHorizons>
      <RebMainNavIberostarPro v-if="isIbp">
        <slot name="iberostar_pro"></slot>
      </RebMainNavIberostarPro>
    </div>
    <!-- .reb-main-nav__panels -->
  </div>
  <!-- .reb-main-nav -->
</template>

<script>
import { OPEN_HORIZONS_MENU_EVENT } from '../../../../shared/user_login/user_login'
import AccessibilityButton from '../AccessibilityButton/AccessibilityButton.vue'
import CurrencySelector from '../CurrencySelector/CurrencySelector.vue'
import LanguageSelector from '../LanguageSelector/LanguageSelector.vue'
import RebMainNavBookings from '../RebMainNavBookings/RebMainNavBookings.vue'
import RebMainNavHorizons from '../RebMainNavHorizons/RebMainNavHorizons.vue'
import RebMainNavHotelsDestinations from '../RebMainNavHotelsDestinations/RebMainNavHotelsDestinations.vue'
import RebMainNavIberostarPro from '../RebMainNavIberostarPro/RebMainNavIberostarPro.vue'
import RebMainNavItem from '../RebMainNavItem/RebMainNavItem.vue'
import RebMainNavLastSearchs from '../RebMainNavLastSearchs/RebMainNavLastSearchs.vue'
import RebMainNavOffers from '../RebMainNavOffers/RebMainNavOffers.vue'

const componentName = 'RebMainNav'

export default {
  name: componentName,
  components: {
    RebMainNavItem,
    RebMainNavHotelsDestinations,
    RebMainNavOffers,
    RebMainNavLastSearchs,
    RebMainNavBookings,
    RebMainNavHorizons,
    RebMainNavIberostarPro,
    LanguageSelector,
    CurrencySelector,
    AccessibilityButton,
  },

  props: {
    isIbp: {
      type: Boolean,
      default: false,
    },
    panelTitle: String,
    mainMenu: Array,
    a11yConfig: Object,
  },

  data() {
    return {
      isUserLogged: false,
      userLastSearches: null,
      selectedItem: null,
    }
  },

  mounted() {
    IB.browsing_sessions.readRedis(this.init)
    this.userLastSearches = localStorage.getItem('last_searches') && JSON.parse(localStorage.getItem('last_searches'))

    document.addEventListener(OPEN_HORIZONS_MENU_EVENT, () => {
      this.setSelectedItem({ badge: 'myiberostar' })
    })
  },

  methods: {
    init() {
      this.isUserLogged = IB.browsing_sessions.isUserLogged()
    },
    setSelectedItem(item) {
      this.selectedItem = item.badge
      // TODO: No he conseguido que funcione con un emit típico
      this.$parent.selectedItem(item.badge)
    },
  },
}
</script>
