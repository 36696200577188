// TODO: Remove lodash library
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'

import { getHotelData } from '../../../../utils/hotels_data_bridge'

import {
  emitHeaderPanelOfferEvents,
  emitUtagAllDestinationsEvent,
  emitUtagMainMenuHotelEvent,
  emitUtagShowCountryEvent,
  emitUtagShowDestinationsEvent,
  emitUtagShowHotelsEvent,
} from './DesktopMenu.analytics'

const DesktopMenu = {
  data() {
    return {
      data: [],
      allDestinations: [],
      allHotels: [],
      continents: [],
      destinations: [],
      hotels: [],
      showContinents: false,
      showContinentsAndCountries: true,
      showDestinations: false,
      showHotels: false,
      showOffers: false,
      showBookings: false,
      showSearches: false,
      showMyIberostar: false,
      headquarter: '',
      continentName: '',
      continentUtagName: '', //Revisar esto para Utag
      countryName: '',
      countryUtagName: '',
      countryUrl: '',
      countryLabel: '',
      destinationName: '',
      destinationUtagName: '',
      destinationLabel: '',
      linkAllDestinationsFooterUtagName: '',
      destinationUrl: '',
      horizons: '',
      promotion: '',
      //search:              '',
      //text:                '',
      destinationUrlText: '',
      countryUrlText: '',
      footerDestinationUrl: '',
      footerDestinationUrlText: '',
      allDestinationsUrl: '',
      allDestinationsUrlText: '',
    }
  },

  /**
   * WATCHERs for UTAG
   *
   * Watch changes in show variables to
   * trigger utag link with some info from component state.
   *
   * The event must only be triggered upon opening
   * each of the different panels.
   * Check PY02500-1073 GSV 468879 for feature briefing.
   */
  watch: {
    showCountry(newValue) {
      if (!newValue) return
      emitUtagShowCountryEvent(this)
    },
    showDestinations(newValue) {
      if (!newValue) return
      emitUtagShowDestinationsEvent(this)
    },
    showHotels(newValue) {
      if (!newValue) return
      emitUtagShowHotelsEvent(this)
    },
    showBookings(newValue) {
      if (newValue === false) {
        // Close bookings start date if opened
        // Refer to app/frontend/src/vendors/rangedatepicker/rangedatepicker.js line 832 for reference
        $(document).trigger('click.datepicker')
      }
    },
  },

  methods: {
    getData() {
      IB.hotels_data.check()
        ? this.processData()
        : IB.hotels_data.get(() => {
          this.processData()
        })
    },
    processData() {
      this.data = IB.hotels_data.getData()
      this.allDestinations = this.data.destinations[1]
      this.allHotels = _groupBy(this.data.hotels[1], 'data.destination')
      this.continents = _sortBy(
        _filter(this.allDestinations, o => o.data.depth == 0),
        'position'
      )
      this.continents.forEach(continent => {
        continent.countries = this.filterDestination(continent.id)
      })
    },
    showFilteredElement(id, elementType, continent = null) {
      if (elementType !== 'destinations' || elementType !== 'hotels') {
        this.showContinentsAndCountries = false
      } else {
        this.showContinentsAndCountries = true
      }
      this.showDestinations = elementType === 'destinations'
      this.showHotels = elementType === 'hotels'
      if (continent !== null) this.continentUtagName = continent

      const chooseDestinationText = document.querySelector('.hotels-and-destinations').getAttribute('data-choose-destination-text')

      if (elementType === 'destinations') {
        const country = _find(this.allDestinations, d => d.id === id)
        const numHotelsInCountry = this.getHotels(country.id).length
        this.destinationLabel = ''
        this.countryName = country.title
        this.countryLabel = `${chooseDestinationText} ${this.countryName}`
        ;(this.countryUtagName = country.data.title_en.toLowerCase()), (this.countryUrl = country.data.url)
        this.headquarter = country.data.headquarter_slug
        this.destinations = this.filterDestination(id)
        this.footerDestinationUrl = this.countryUrl
        this.countryUrlText = `${document.querySelector('.destination-link').getAttribute('data-selected-destinations-text') + this.countryName}${
          numHotelsInCountry ? ` (${numHotelsInCountry})` : ''
        }`
        this.footerDestinationUrlText = this.countryUrlText
        this.linkAllDestinationsFooterUtagName = 'country'
      }

      if (elementType === 'hotels') {
        const destination = _find(this.allDestinations, d => d.id === id)
        this.countryLabel = ''
        this.destinationName = destination.title
        this.destinationLabel = `${chooseDestinationText} ${this.countryName} /  ${this.destinationName}`
        ;(this.destinationUtagName = destination.data.title_en.toLowerCase()), (this.destinationUrl = destination.data.url)
        this.hotels = this.getHotels(id)
        this.footerDestinationUrl = this.destinationUrl
        this.destinationUrlText = document.querySelector('.destination-link').getAttribute('data-selected-destinations-text') + this.destinationName
        this.footerDestinationUrlText = this.destinationUrlText
        this.linkAllDestinationsFooterUtagName = 'city'
      }
    },
    returnPanel(elementType) {
      this.showOffers = elementType === 'offers'
      this.showBookings = elementType === 'bookings'
      this.showSearches = elementType === 'searches'
      this.showMyIberostar = elementType === 'myiberostar'
      if (this.showContinents && elementType === 'hide') {
        this.showContinents = false
        this.footerDestinationUrl = this.allDestinationsUrl
        this.footerDestinationUrlText = this.allDestinationsUrlText
      } else {
        this.allDestinationsUrl = document.querySelector('.destination-link')?.getAttribute('data-all-destinations-url')
        this.footerDestinationUrl = this.allDestinationsUrl
        this.allDestinationsUrlText = document.querySelector('.destination-link')?.getAttribute('data-all-destinations-text')
        this.footerDestinationUrlText = this.allDestinationsUrlText

        this.showContinents = elementType === 'continents' || elementType === 'continentsAndCountries' || elementType === 'destinations' || elementType === 'hotels'
        this.showContinentsAndCountries = elementType === 'continents' || elementType === 'continentsAndCountries'
        this.showDestinations = elementType === 'destinations'
        this.showHotels = elementType === 'hotels'
      }

      if (elementType === 'continentsAndCountries') {
        this.showDestinations = false
        this.showContinentsAndCountries = true
        this.footerDestinationUrl = this.allDestinationsUrl
        this.footerDestinationUrlText = this.allDestinationsUrlText
        this.linkAllDestinationsFooterUtagName = 'continent'
        this.destinations = []
      }

      if (elementType === 'destinations') {
        this.showDestinations = true
        this.showHotels = false
        this.linkAllDestinationsFooterUtagName = 'country'
        this.footerDestinationUrl = this.countryUrl
        this.footerDestinationUrlText = this.countryUrlText
        this.hotels = []
      }

      if (this.showOffers) {
        emitHeaderPanelOfferEvents()
      }

      // Ibercuba Microsite
      if (document.documentElement.dataset.inMicrosite === 'ibercuba') {
        const cubaContinent = this.continents[0]
        const cubaCountry = cubaContinent.countries[0]
        this.showFilteredElement(cubaCountry.id, 'destinations', cubaContinent.title_normalize)
      }
    },
    filterDestination(id) {
      return _sortBy(
        _filter(this.allDestinations, o => {
          return o.data.parent_id == id && this.getHotels(o.id).length
        }),
        'title'
      )
    },
    getHotels(id) {
      let hotels = _filter(this.allHotels[id], o => o.data.destination == id)
      if (!hotels.length) {
        const t = this.filterDestination(id)
        t.forEach(element => {
          hotels = hotels.concat(this.getHotels(element.id))
        })
      }

      return _sortBy(hotels, 'title')
    },
    handleUtagMainMenuHotelClick(hotel_id) {
      const hotelData = getHotelData(hotel_id)
      emitUtagMainMenuHotelEvent(this, hotelData)
    },
    handleUTagAllDestinationsClick() {
      emitUtagAllDestinationsEvent()
    },
  },
}

export default DesktopMenu
