<template>
  <div class="reb-main-nav__currency-selector">
    <slot></slot>
  </div>
</template>

<script>
const componentName = 'CurrencySelector';

export default {
  name: componentName,

  mounted() {
    setTimeout(function() {
      IB.currencyForm.init();
      $('.reb-main-nav__currency-selector .currency-form select').formikation();
    }, 500)
  }
}
</script>
