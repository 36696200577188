// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { openGallery } from '../../../blocks/hotel_gallery/hotel_gallery'
import FastbookingState, { UPDATE_FASTBOOKING_STATE_EVENT } from '../../../shared/fastbooking/fastbooking.state'
import { initTestRebHeader } from '../../../utils/test_reb_header'
import MainNav from '../../../vue/main_nav/reb/vue_main_nav'

dayjs.extend(customParseFormat)

const lang = document.documentElement.getAttribute('data-js-lang')
const format = document.querySelector('html').dataset.dateFormat

/**
 * Main selectors
 */
const mainRebHeader = document.querySelector('.js-reb-header')
const headerPanel = mainRebHeader?.querySelector('.js-reb-header-panel') // Panel
const mainHeader = document.querySelector('.js-main-header')

/**
 * Selector for the menu toggle
 */
const menuToggle = document.querySelector('.js-vue-menu-toggle')

/**
 * Navbar
 */
const navBar = mainRebHeader?.querySelector('.js-reb-header-content')
/**
 * Placeholder to move fastbooking to the header
 */
const fastbookingInputFake = navBar?.querySelector('.js-fb-fake-input')

// List of pages where the test has to be started but in a different way.
export const EXCEPTIONS_REB_TEST = ['destination', 'destination-index', 'offer', 'you do you']

function moveFastBookingSticky() {
  const fastbooking = document.querySelector('.fastbooking')
  const panelBody = headerPanel?.querySelector('.js-reb-header-panel-body')
  panelBody.appendChild(fastbooking)
  if (fastbooking.classList.contains('hidden')) {
    fastbooking.classList.remove('hidden')
  }
}

function restoreFastBookingSticky() {
  const fastBookingWrapper = document.querySelector('.fastbooking-wrapper')
  const fastbooking = document.querySelector('.fastbooking')
  fastBookingWrapper?.appendChild(fastbooking)
}

function openPanel() {
  headerPanel.classList.remove('is-hidden')
  mainRebHeader.querySelector('.js-reb-header-hotel-nav')?.classList.add('is-hidden')
  if (mainRebHeader.offsetWidth > 0 && mainRebHeader.offsetHeight > 0) {
    // If header is visible, move fastbooking to the header
    moveFastBookingSticky()
  }
}

function initClickOpenPanel() {
  const fakeInput = navBar?.querySelector('.js-reb-header-fake .js-fb-fake-input')
  const button = navBar?.querySelector('.js-reb-header-fake .js-fb-button')

  ;[button, fakeInput].forEach(item => {
    item.addEventListener('click', e => {
      e.preventDefault()
      openPanel()
    })
  })
}

function closePanel() {
  headerPanel.classList.add('is-hidden')
  mainRebHeader.querySelector('.js-reb-header-hotel-nav')?.classList.remove('is-hidden')
  IB.fastbooking.sticky.close()
}

function initClickClosePanel() {
  const iconClosePanel = headerPanel?.querySelector('.close-panel')
  iconClosePanel.addEventListener('click', closePanel)
}

function placeholderLastSearch() {
  // Capture event
  document.addEventListener(UPDATE_FASTBOOKING_STATE_EVENT, () => {
    if (FastbookingState.getState().place_to_go !== undefined) {
      if (FastbookingState.getState().dates !== undefined) {
        const checkIn = dayjs(FastbookingState.getState()?.dates?.check_in, format).format('D MMM') // D-M numeric format
        // Check if there is no Check_out. If there is, add separator
        const checkOut = FastbookingState.getState().dates.check_out
          ? `- ${dayjs(FastbookingState.getState()?.dates?.check_out, format).format('D MMM')}`
          : '' // D-M numeric format
        fastbookingInputFake.innerHTML = `${
          FastbookingState.getState().place_to_go.name
        } <span></span> ${checkIn} ${checkOut}`
      } else {
        fastbookingInputFake.innerHTML = `${FastbookingState.getState().place_to_go.name}`
      }
    } else {
      fastbookingInputFake.textContent = fastbookingInputFake.getAttribute('data-initial-message')
    }
  })
  if (!IB.fastbooking_state) return

  IB.fastbooking_state.triggerUpdateEvent()
}

function toggleMenu() {
  const menu = mainRebHeader?.querySelector('.js-reb-header-menu')
  const fakeInputHeader = navBar?.querySelector('.js-reb-header-fake')

  menuToggle.classList.toggle('close')
  menu.classList.toggle('is-hidden')
  fakeInputHeader.classList.toggle('is-hidden')
  ScrollLock.toggle()
}

function showContent() {
  moveFastBookingSticky()
}

function hideContent() {
  closePanel()
  restoreFastBookingSticky()
}

function copyInternalNav(internalNav) {
  const hotelNav = document.querySelector('.js-reb-header-hotel-nav')
  const fakeItems = hotelNav.querySelectorAll('.menu_item')

  internalNav.classList.add('internal-nav__hidden')

  internalNav.querySelector('.js-internal-nav-list').prepend(...fakeItems)
  fakeItems.forEach(item => {
    item.classList.remove('hidden')
  })

  hotelNav.append(internalNav)
  setTimeout(() => {
    internalNav.classList.remove('internal-nav__hidden')
  }, 100)
}

function initOpenGalleryButton() {
  mainRebHeader.querySelector('.js-open-hotel-gallery')?.addEventListener('click', e => {
    e.preventDefault()
    openGallery()
  })
}

function init() {
  if (!mainRebHeader) return
  initClickOpenPanel()
  initClickClosePanel()

  import(`dayjs/locale/${lang}.js`).then(() => {
    dayjs.locale(lang)
    placeholderLastSearch()
  })

  menuToggle.addEventListener('click', e => {
    e.preventDefault()
    toggleMenu()
  })

  initOpenGalleryButton()
  if (fastbookingInputFake) return

  fastbookingInputFake.textContent =
    FastbookingState.getState().place_to_go?.name ?? fastbookingInputFake.getAttribute('data-initial-message')
}

const MainHeader = {
  init,
  showContent,
  hideContent,
  openPanel,
  copyInternalNav,
}

document.addEventListener(
  'DOMContentLoaded',
  function () {
    if (initTestRebHeader) {
      mainRebHeader.classList.remove('hidden')
      mainHeader?.remove()
      MainHeader.init()
      MainNav.init()
    } else {
      mainRebHeader?.remove()
    }
  },
  false
)

export default MainHeader
