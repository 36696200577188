<template>
  <li :class="`reb-main-nav-item t-h3 ${(this.isSelected? 'reb-main-nav-item--selected': '')}`">
    <a
      :class="getClass('link', item.css_class)"
      :href="item.url"
      :rel="item.rel"
      :target="item.target"
      :data-no-turbolink="item.turbolinks? false: true"
      :data-visible-to-the-club-user="item.visible_to_the_club_user? item.visible_to_the_club_user: false"
      :data-event-label="item.kind == 'page'? item.event_name: false"
      :data-method="item.logout_method? item.logout_method: false"
      @click="onClick"
    >
      {{ item.name ? item.name : item.item.name }}
      <span class="s s-s s-arrow-right-fat"></span>
    </a>
  </li>
</template>

<script>
const componentName = 'RebMainNavItem';

import MenuItem from '../../mixins/MenuItem/MenuItem';

export default {
  name: componentName,

  mixins: [ MenuItem ],

  props: {
    item: Object,
    utagEventInfo: Object,
    isSelected: false
  },

  mounted() {
    if (this.item.itemClass) {
      this.item.itemClass.split(' ').forEach((itemClass) => {
        this.$el.classList.add(itemClass);
      });
    }
  },

  methods: {
    onClick(event) {
      this.sendUtagEvent();
      this.$emit('selected', this.item);
    }
  }
}
</script>
