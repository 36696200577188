import { sendUtagEvent } from '../../../../core/analytics/utag_events'

export function emitHeaderPanelOfferEvents() {
  const headerMenuOffers = document.querySelector('.utag-header-menu-offers')

  if (!headerMenuOffers) return

  const eecInfoPromo = headerMenuOffers.dataset.utagEccPromoList

  if (eecInfoPromo) {
    sendUtagEvent({ data: JSON.parse(eecInfoPromo) })
  }

  const eecInfoProduct = headerMenuOffers.dataset.utagEccProductList

  if (eecInfoProduct) {
    sendUtagEvent({ data: JSON.parse(eecInfoProduct) })
  }
}

export function emitUtagAllDestinationsEvent() {
  sendUtagEvent({
    data: {
      event_name: 'menu_main',
      event_cat: 'main-menu menu',
      event_act: 'view all hotels and destinations',
      event_lbl: '',
      event_purpose: 'information',
      event_structure: 'header',
    },
  })
}

export function emitUtagMainMenuHotelEvent(linkElement, hotelData) {
  if (!linkElement || !hotelData) return

  sendUtagEvent({
    data: {
      event_cat: 'main-menu menu',
      event_name: 'menu_main',
      event_act: 'hotels and destinations',
      event_lbl: 'hotel',
      event_purpose: 'information',
      event_structure: 'header',
      destination_continent: linkElement.continentUtagName,
      destination_country: linkElement.countryUtagName,
      destination_city: linkElement.destinationUtagName,
      hotel_sku: hotelData.data.hotel_code,
      hotel_short_name: hotelData.data.abrev,
      hotel_name: hotelData.title.toLowerCase(),
      hotel_segment: hotelData.data.segmentations[0]?.class || '',
      hotel_subsegment: hotelData.data.subsegmentations[0]?.class || '',
      hotel_brand: hotelData.data.utag_data_brand || '',
      hotel_is_only_adults: hotelData.data.adults_only,
      hotel_stars: hotelData.data.hotel_category_number,
      hotel_headquarter_final: linkElement.headquarter,
    },
  })
}

export function emitUtagShowCountryEvent(linkElement) {
  if (!linkElement) return

  sendUtagEvent({
    data: {
      event_cat: 'main-menu menu',
      event_name: 'menu_main',
      event_purpose: 'information',
      event_structure: 'header',
      event_act: 'hotels and destinations',
      event_lbl: 'continent',
      destination_continent: linkElement.continentUtagName,
      hotel_headquarter_final: linkElement.headquarter,
    },
  })
}

export function emitUtagShowDestinationsEvent(linkElement) {
  if (!linkElement) return

  sendUtagEvent({
    data: {
      event_cat: 'main-menu menu',
      event_name: 'menu_main',
      event_purpose: 'information',
      event_structure: 'header',
      event_act: 'hotels and destinations',
      event_lbl: 'country',
      destination_continent: linkElement.continentUtagName,
      destination_country: linkElement.countryUtagName,
      hotel_headquarter_final: linkElement.headquarter,
    },
  })
}

export function emitUtagShowHotelsEvent(linkElement) {
  if (!linkElement) return

  sendUtagEvent({
    data: {
      event_cat: 'main-menu menu',
      event_name: 'menu_main',
      event_purpose: 'information',
      event_structure: 'header',
      event_act: 'hotels and destinations',
      event_lbl: 'city',
      destination_continent: linkElement.continentUtagName,
      destination_country: linkElement.countryUtagName,
      destination_city: linkElement.destinationUtagName,
      hotel_headquarter_final: linkElement.headquarter,
    },
  })
}
