<template>
  <div class="reb-main-nav__offers">
    <slot></slot>
  </div>
</template>

<script>
const componentName = 'RebMainNavOffers';

export default {
  name: componentName,
}
</script>
