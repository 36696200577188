import { createApp } from 'vue'

import DesktopMenu from '../../src/mixins/DesktopMenu/DesktopMenu'
import LoadStyles from '../../src/mixins/LoadStyles/LoadStyles'
import Menu from '../../src/mixins/Menu/Menu'

import privatearea from '../../../../../frontend/src/vue/src/components/PrivateArea/PrivateArea'
import rebmainnav from '../../src/components/RebMainNav/RebMainNav'

const BASE_ID_SELECTOR = '#vue-main-nav-reb'
const BASE_SELECTOR = '.vue-main-nav-reb'
const LAST_SEARCHES_SELECTOR = '.reb-main-nav-item--last-searches'

function init() {
  if (!document.querySelector(BASE_ID_SELECTOR)) return

  const rebMainNav = {
    mixins: [Menu, DesktopMenu],
    // Root components must be imported in lowercase
    components: {
      rebmainnav,
      privatearea,
    },
    data: {
      baseSelector: BASE_SELECTOR,
      lastSearchesSelector: LAST_SEARCHES_SELECTOR,
      initializedBookingsForm: false,
      initializedLastSearches: false,
      imagesRequested: false,
      privateAreaConfig: JSON.parse(document.querySelector(BASE_ID_SELECTOR).dataset.privateAreaConfig),
    },
    mounted() {
      setTimeout(() => {
        this.getData()
        this.initTemplates()
      }, 500)
    },
    methods: {
      selectedItem(itemName) {
        this.returnPanel(itemName)
        this.loadImages()

        switch (itemName) {
          case 'bookings':
            $(`${BASE_SELECTOR} .panel.bookings .hidden-img`).addClass('visible-img')
            $(`${BASE_SELECTOR} .panel.bookings .hidden-img`).removeClass('hidden-img')
            IB.lazyImg.loadAllImagesSelector($(`${this.baseSelector} .panel.bookings .visible-img`))
            if (!this.initializedBookingsForm) {
              IB.forms.reservation_management.init($(`${this.baseSelector} .js-reservation-management-form`))
              IB.formUtagEvents.init()
              this.initializedBookingsForm = true
            }
            break
          case 'searches':
            if (!this.initializedLastSearches) {
              $(`${BASE_SELECTOR} .panel.searches .hidden-img`).addClass('visible-img')
              $(`${BASE_SELECTOR} .panel.searches .hidden-img`).removeClass('hidden-img')
              IB.lazyImg.loadAllBackgroundImgSelector($(`${this.baseSelector} .panel.searches .visible-img`))
              this.initializedLastSearches = true
            }
            break
          case 'my-iberostar':
            $(`${BASE_SELECTOR} .panel.my-iberostar .hidden-img`).addClass('visible-img')
            $(`${BASE_SELECTOR} .panel.my-iberostar .hidden-img`).removeClass('hidden-img')
            if ($('.js-user-options-dropdown-link[data-menu-name="my-iberostar"]').length) {
              // If user is logged
              $('li.user-notifications').addClass('is-displayed')
              IB.lazyImg.loadAllBackgroundImgSelector($(`${this.baseSelector} .panel.my-iberostar .visible-img`))
            }
            break
          default:
        }
      },
      loadImages() {
        !this.imagesRequested && IB.lazyImg.loadAllImagesSelector($(this.baseSelector))
        this.imagesRequested = true
      },
    },
  }

  const app = createApp(rebMainNav)
  /**
   * TODO: Use Composables instead of Mixins
   * https://vuejs.org/api/options-composition.html#mixins
   * https://vuejs.org/guide/reusability/composables.html
   */
  app.mixin(LoadStyles)
  app.mount(BASE_ID_SELECTOR)
}

const MainNav = {
  init,
}

export default MainNav
