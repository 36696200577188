<template>
  <div class="reb-main-nav__bookings">
    <slot></slot>
  </div>
</template>

<script>
import initUserLogin from '../../../../shared/user_login/user_login'

const componentName = 'RebMainNavBookings'

export default {
  name: componentName,
  mounted() {
    initUserLogin()
  },
}
</script>
