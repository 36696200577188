/**
 * Hotels Data Bridge
 *
 * Collection of functions to search for data in fastbooking_data
 * without including them in hotels_data.js (including them there
 * mean to always load the code on page load, bad for performance).
 *
 * Import the needed function in your JS.
 *
 * */

/**
  * getHotelsByChildDestination
  *
  * Fetch the hotels belonging to a destination id,
  * ordered by ascending price.
  *
  * The destination is not a country but a province.
  * (therefore the "child"- country is a first level,
  * parent, destination).
  *
  * @params
  * destinationId: the destination in the format d<id>,
  *                as it appears in the fastbooking_data.
  * example:
  *   getHotelsByDestination("d20")
  * to fetch Majorca's hotels ordered by price.
  */
export function getHotelsByChildDestination(destinationId) {
  let hotelsData = getHotels();

  hotelsData = hotelsData.filter((hotel) => hotel.data.destination === destinationId);

  // ordered by ascending price
  return hotelsData.sort((a, b) => +a.data.price - +b.data.price);
}


/**
 * getHotelsByCountry
 *
 * Fetch the hotels belonging to a country id,
 * ordered by ascending price.
 *
 * @params
 * countryId: the id of the country.
 * example:
 *    getHotelsByCountry(1)
 * to fetch Spain's hotels ordered by price.
 */
export function getHotelsByCountry(countryId) {
  let hotelsData = getHotels();

  hotelsData = hotelsData.filter((hotel) => hotel.data.country === countryId);

  // ordered by ascending price
  return hotelsData.sort((a, b) => +a.data.price - +b.data.price);
}


/**
 * getHotels
 *
 * Get the hotels' array from fastbooking data
 * with the default order (alphabetical).
 *
 */
export function getHotels() {
  let hotelsData = IB.hotels_data.getData();

  // getting hotels' array
  // the first position is just the "Hotels" title,
  // the second one has all the data.
  let hotelArrayPosition = 1;
  hotelsData = hotelsData.hotels[hotelArrayPosition];

  return hotelsData;
}

/**
   * Given hotel id, returns hotel object from fastbooking_data json
   * @param {string} hotelId
   */
export function getHotelData(hotelId) {
  let fastbookingData = IB.hotels_data.getData();
  let hotels = fastbookingData?.hotels[1];

  // Parse hotelId to string and prepend 'h' to hotelId if not present
  hotelId = '' + hotelId;
  hotelId = hotelId[0] === 'h' ? hotelId : 'h' + hotelId;

  // Find and return hotel object from fastbookingData
  return hotels?.find(function (hotel) {return hotel.id === hotelId;});
}

/**
 * Given hotel id, returns the field with the CRS management value.
 * @param {string} hotelId
 */
export function getCrsManagementId(hotelId) {
  let hotel = getHotelData(hotelId);
  return hotel.data.crs_management_id;
}

/**
 * Given hotel id, returns the field with the CRS hotel code.
 * @param {string} hotelId
 */
 export function getCrsHotelCode(hotelId) {
  let hotel = getHotelData(hotelId);
  return hotel.data.hotel_code;
}

/**
 * Given hotel code, returns hotel
 * @param {string} hotelCode
 * @return {object} hotel
 */
export function getHotelByHotelCode(hotelCode) {
  let fastbookingData = IB.hotels_data.getData();
  let hotels = fastbookingData?.hotels[1];

  return hotels?.find(hotel => hotel.data.hotel_code === hotelCode);
}

/**
 * Given hotel code, return hotel ID
 * @param {string} hotelCode
 * @return {Number} hotel ID
 */
export function getHotelIDfromHotelCode(hotelCode) {
  let hotel = getHotelByHotelCode(hotelCode);
  return hotel?.id;
}

/**
 * Given hotel codes, return hotel IDs in array
 * @param {string} hotelCodes
 * @return {array of Number} hotelIDs
 */
export function getHotelIDsFromHotelCodes(hotelCodes) {
  hotelCodes = hotelCodes.split(',');
  let hotelIDs = [];
  hotelCodes.forEach(code => hotelIDs.push(+getHotelIDfromHotelCode(code)?.substring(1)));
  hotelIDs = hotelIDs.length && hotelIDs.filter(id => id !== undefined && id !== null && !isNaN(id));

  return hotelIDs;
}
