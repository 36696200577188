<template>
  <div class="iberostar_pro">
    <slot></slot>
  </div>
</template>

<script>
const componentName = 'RebMainNavIberostarPro';

export default {
  name: componentName,
}
</script>
