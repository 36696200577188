<template>
  <div class="reb-main-nav__hotels-and-destinations">
    <slot></slot>
  </div>
</template>

<script>
const componentName = 'RebMainNavHotelsDestinations';

export default {
  name: componentName,
}
</script>
