<template>
  <div class="reb-main-nav__language-selector">
    <slot></slot>
  </div>
</template>

<script>
const componentName = 'LanguageSelector';

export default {
  name: componentName,
}
</script>
